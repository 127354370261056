<template lang="">
    <div class="p-5">
      

        <b-card   
    :title="$t('HomePage.contract')"
    img-src="https://picsum.photos/600/100/?image=25"
    img-alt="Image"
    img-top
    tag="article"
    
    class="mb-2"
  >
    <b-card-text>
        


 
<b-list-group>
  <b-list-group-item>ກົມກົງສຸນ, ກະຊວງການຕ່າງປະເທດ</b-list-group-item>
  <b-list-group-item>ໂທ: 021 254176</b-list-group-item>
  <b-list-group-item>ແຟັກ: 021 243462</b-list-group-item>
  <b-list-group-item>ອີເມວ: consular@mofa.gov.la</b-list-group-item>
  <b-list-group-item>www.consularlao.gov.la
  </b-list-group-item>
</b-list-group>
    </b-card-text>

    
  </b-card>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>